import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyCn9ULIhRnaIU-mGnX8UaFEYfHs6Bi89gM",
    authDomain: "fis-wedding2024.firebaseapp.com",
    projectId: "fis-wedding2024",
    storageBucket: "fis-wedding2024.appspot.com",
    messagingSenderId: "125522802997",
    appId: "1:125522802997:web:253cff05a3662adeca347c",
    measurementId: "G-WPK44BM872"
  };

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const firestore = getFirestore(app);
export const storage = getStorage(app);
