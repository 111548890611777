import React, { useState } from 'react';
import { firestore } from '../firebase'; // Importă configurarea Firebase
import { collection, doc, setDoc } from 'firebase/firestore';

const GuestForm = () => {
  const [guests, setGuests] = useState([
    { name: '', status: 'Meniu basic', isUnder7: false },
  ]);

  const handleChange = (index, field, value) => {
    const newGuests = [...guests];
    newGuests[index][field] = value;
    setGuests(newGuests);
  };

  const handleAddGuest = () => {
    setGuests([...guests, { name: '', status: 'Meniu basic', isUnder7: false }]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (guests[0].name.trim() === '') {
      alert('The first guest name is required.');
      return;
    }

    const groupName = guests[0].name.trim();

    try {
      // Creează un document nou cu numele primului invitat ca identificator
      const groupDocRef = doc(collection(firestore, 'guestlist'), groupName);
      await setDoc(groupDocRef, { guests });

      setGuests([{ name: '', status: 'Meniu basic', isUnder7: false }]);
      alert('Guests added successfully!');
    } catch (error) {
      console.error('Error adding guests: ', error);
    }
  };

  return (
    <div>
      <h2>Confirmare locuri</h2>           
      <form onSubmit={handleSubmit}>
        {guests.map((guest, index) => (
          <div key={index} style={{ marginBottom: '20px' }}>
            <input
              type="text"
              value={guest.name}
              onChange={(e) => handleChange(index, 'name', e.target.value)}
              placeholder="Enter name"
              required
            />
            <select
              value={guest.status}
              onChange={(e) => handleChange(index, 'status', e.target.value)}
            >
              <option value="Meniu basic">Meniu basic</option>
              <option value="Meniu copil">Meniu copil</option>
              <option value="Meniu vegetarian">Meniu vegetarian</option>
              <option value="Meniu vegan">Meniu vegan</option>
            </select>
            {guest.status === 'Meniu copil' && (
              <div>
                <label>
                  <input
                    type="checkbox"
                    checked={guest.isUnder7}
                    onChange={(e) => handleChange(index, 'isUnder7', e.target.checked)}
                  />
                  Este sub 7 ani?
                </label>
              </div>
            )}
          </div>
        ))}
        <button type="button" onClick={handleAddGuest}>
          Adaugă membru
        </button>
        <button type="submit">Submit All Guests</button>
      </form>
    </div>
  );
};

export default GuestForm;
